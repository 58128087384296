import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import Prism from "prismjs";

import "prismjs/themes/prism-tomorrow.css";
import "./App.css";

function App() {
  const [markdown, setMarkdown] = useState("");
  const contentRef = useRef(null);

  useEffect(() => {
    fetch("/markdown-content.md")
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      })
      .catch((error) => console.error("Error fetching markdown:", error));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = document.documentElement;
      localStorage.setItem('scrollPosition', scrollTop.toString());
    };

    const restoreScrollPosition = () => {
      const savedScrollPosition = localStorage.getItem("scrollPosition");
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", restoreScrollPosition);

    // Restore scroll position after content has loaded
    if (markdown) {
      restoreScrollPosition();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", restoreScrollPosition);
    };
  }, [markdown]);

  useEffect(() => {
    Prism.highlightAll();
  }, [markdown]);

  const nameColors = {
    'strangepark': '#FF6B6B',
    'sonhyojin': '#4ECDC4',
    '재윤 최': '#45B7D1',
    'Minsik Kim': '#FFA07A'
  };

  const customRenderers = {
    p: ({ node, children }) => {
      // Check for audio
      if (node.children && node.children.length > 0) {
        if (node.children[0].type === "text") {
          if (node.children[0].value.startsWith("[audio:")) {
            const match = node.children[0].value.match(
              /\[audio:(.*?)\](\((.*?)\))?/
            );
            if (match) {
              const audioSrc = match[1];
              const caption = match[3] || "";
              return (
                <figure>
                  <audio controls>
                    <source src={audioSrc} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  {caption && <figcaption>{caption}</figcaption>}
                </figure>
              );
            } else {
              return (
                <p style={{ color: "red" }}>
                  Invalid audio syntax: {node.children[0].value}
                </p>
              );
            }
          }
        }
      }

      // Check for images
      if (node.children[0].type === "image") {
        return <>{children}</>;
      }

      // Default paragraph rendering
      return <p>{children}</p>;
    },

    image: ({ node, ...props }) => (
      <figure>
        <img
          style={{ maxWidth: "100%", height: "auto" }}
          {...props}
          alt={props.alt || ""}
        />
        {props.title && <figcaption>{props.title}</figcaption>}
      </figure>
    ),

    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <pre className={className}>
          <code className={className} {...props}>
            {children}
          </code>
        </pre>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },

    em: ({ node, children }) => {
      // This will style the line numbers
      return <em style={{ color: "#888" }}>{children}</em>;
    },

    strong: ({node, children}) => {
      const text = children;
      const colonIndex = text.indexOf(':');
    
      if (colonIndex !== -1) {
        const name = text.slice(0, colonIndex);
        const rest = text.slice(colonIndex);
    
        const color = nameColors[name] || 'inherit';
        return (
          <strong>
            <span style={{color: color}}>{name}</span>
            {rest}
          </strong>
        );
      }
    
      return <strong>{children}</strong>;
    }
  };

  return (
    <div className="App" ref={contentRef}>
      <div className="markdown-content">
        <ReactMarkdown
          components={customRenderers} // Use 'components' for newer versions
          // renderers={customRenderers}  // Uncomment this and comment the above line for older versions if needed
        >
          {markdown}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default App;
